import React, { useState } from 'react'
import { MENU_ITEM } from '@/constants'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
    const [activeMenuItem, setActiveMenuItem] = useState(MENU_ITEM.HOME)

    const updateActiveMenuItem = (menuItem) => {
        setActiveMenuItem(menuItem);
    }

    return (
        <AppContext.Provider value={{ activeMenuItem, updateActiveMenuItem }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
export { AppProvider }
