import React from 'react'
import { Outlet } from 'react-router-dom'
import { MENU_ITEM } from '@/constants'
import { AppProvider } from '@/store/context'
import Header from '@/components/header'
import Footer from '@/components/footer'

function App() {  
  return (
    <AppProvider>
      <div className="w-full">
        {/* Header section */}
        <Header activeMenuItem={MENU_ITEM.HOME} />
        {/* Content section */}
        <Outlet />
        {/* Footer section */}
        <Footer />
      </div>
    </AppProvider>
  )
}

export default App
