import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer footer-center flex flex-col items-center px-4 py-12 space-y-4 text-base lg:text-xl">
                <aside>
                    <p><a href="https://www.pynovate.com">Pynovate Web Development Studio</a> &copy; 2022-2024. All rights reserved.</p>
                </aside>
            </footer>
        )
    }
}