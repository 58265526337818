import { lazy, Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import App from '@/App'
import NotFound from '@/pages/not_found'
import Loading from '@/components/loading'


const HomeLazy = lazy(() => import('@/pages/home'))
const ServicesLazy = lazy(() => import('@/pages/services'))
const ContactLazy = lazy(() => import('@/pages/contact'))
const PrivacyPolicyLazy = lazy(() => import('@/pages/privacy_policy'))

const RestaurantLazy = lazy(() => import('@/pages/restaurant'))
const RestaurantHomeLazy = lazy(() => import('@/pages/restaurant/pages/home'))
const RestaurantGalleryLazy = lazy(() => import('@/pages/restaurant/pages/gallery'))
const RestaurantMenuLazy = lazy(() => import('@/pages/restaurant/pages/menu'))
const RestaurantContactLazy = lazy(() => import('@/pages/restaurant/pages/contact'))
const RestaurantAboutLazy = lazy(() => import('@/pages/restaurant/pages/about'))

const ClinicLazy = lazy(() => import('@/pages/clinic'))
const ClinicHomeLazy = lazy(() => import('@/pages/clinic/pages/home'))
const ClinicDoctorsLazy = lazy(() => import('@/pages/clinic/pages/doctors'))
const ClinicNewsLazy = lazy(() => import('@/pages/clinic/pages/news'))
const ClinicCareerLazy = lazy(() => import('@/pages/clinic/pages/career'))
const ClinicAboutLazy = lazy(() => import('@/pages/clinic/pages/about'))
const ClinicBookLazy = lazy(() => import('@/pages/clinic/pages/book'))

const ClubLazy = lazy(() => import('@/pages/club'))
const ClubHomeLazy = lazy(() => import('@/pages/club/pages/home'))
const ClubCoachesLazy = lazy(() => import('@/pages/club/pages/coaches'))
const ClubAboutLazy = lazy(() => import('@/pages/club/pages/about'))
const ClubBookLazy = lazy(() => import('@/pages/club/pages/book'))

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                element: <Suspense fallback={<Loading />}><HomeLazy /></Suspense>
            },
            {
                path: 'services',
                element: <Suspense fallback={<Loading />}><ServicesLazy /></Suspense>
            },
            {
                path: 'contact',
                element: <Suspense fallback={<Loading />}><ContactLazy /></Suspense>
            },
            {
                path: 'privacy-policy',
                element: <Suspense fallback={<Loading />}><PrivacyPolicyLazy /></Suspense>
            }
        ]
    },
    {
        path: '/restaurant',
        element: <Suspense fallback={<Loading />}><RestaurantLazy /></Suspense>,
        children: [
            {
                index: true,
                element: <Suspense fallback={<Loading />}><RestaurantHomeLazy /></Suspense>
            },
            {
                path: 'gallery',
                element: <Suspense fallback={<Loading />}><RestaurantGalleryLazy /></Suspense>
            },
            {
                path: 'menu',
                element: <Suspense fallback={<Loading />}><RestaurantMenuLazy /></Suspense>
            },
            {
                path: 'about',
                element: <Suspense fallback={<Loading />}><RestaurantAboutLazy /></Suspense>
            },
            {
                path: 'contact',
                element: <Suspense fallback={<Loading />}><RestaurantContactLazy /></Suspense>
            }
        ]
    },
    {
        path: '/clinic',
        element: <Suspense fallback={<Loading />}><ClinicLazy /></Suspense>,
        children: [
            {
                index: true,
                element: <Suspense fallback={<Loading />}><ClinicHomeLazy /></Suspense>
            },
            {
                path: 'doctors',
                element: <Suspense fallback={<Loading />}><ClinicDoctorsLazy /></Suspense>
            },
            {
                path: 'news',
                element: <Suspense fallback={<Loading />}><ClinicNewsLazy /></Suspense>
            },
            {
                path: 'career',
                element: <Suspense fallback={<Loading />}><ClinicCareerLazy /></Suspense>
            },
            {
                path: 'about',
                element: <Suspense fallback={<Loading />}><ClinicAboutLazy /></Suspense>
            },
            {
                path: 'book',
                element: <Suspense fallback={<Loading />}><ClinicBookLazy /></Suspense>
            }
        ]
    },
    {
        path: '/club',
        element: <Suspense fallback={<Loading />}><ClubLazy /></Suspense>,
        children: [
            {
                index: true,
                element: <Suspense fallback={<Loading />}><ClubHomeLazy /></Suspense>
            },
            {
                path: 'coaches',
                element: <Suspense fallback={<Loading />}><ClubCoachesLazy /></Suspense>
            },
            {
                path: 'about',
                element: <Suspense fallback={<Loading />}><ClubAboutLazy /></Suspense>
            },
            {
                path: 'book',
                element: <Suspense fallback={<Loading />}><ClubBookLazy /></Suspense>
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />,
    }
])

export default router